.App {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  margin: 0 auto;
}

.App__hint {
  color: #aaa;
}
